import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import { useCallback, useMemo } from 'react';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import momentTz from 'moment-timezone';
import { MbscPageLoadingEvent } from '@mobiscroll/react';
import { CalendarFilterInterface } from 'components/v2/CalendarFilter/interfaces';
import { getTimeZoneDateTime } from 'utils/hooks/GetTimezones/getTimezones';
import { CustomCalendarEvent } from '../components/CalendarEvent/CalendarEvent';
import { highLightEvents } from './filterFunctions';
import { useGetAccountInfo } from 'utils/hooks/GetAccountInfo/getAccountInfo';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useFetchFilterList } from '../../CalendarFilterSection/hooks/GetFilterList';
import { useAppointmentsEventByBatch } from './useAppointmentsEventByBatch';
import {
  calendarAppointmentList,
  calendarFilters
} from 'redux/calendarAppointmentList/calendarAppointmentListDataSlice';
import { useAppSelector } from 'redux/hooks';

const FETCH_NUMBER_OF_DAYS = 7;

/* Adjust to 5 for reduce performance issue. */
export const BATCH_CHUNK_SIZE = 5;

export const chunkArray = (array: string[], chunkSize: number) => {
  return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, index) =>
    array.slice(index * chunkSize, index * chunkSize + chunkSize)
  );
};

const rearrangeSelectedChunks = (completeList: string[][], selectedIds: string[]) => {
  return completeList
    .map((group) => group.filter((id) => selectedIds.includes(id)))
    .filter((group) => group.length > 0);
};

export const getIds = (items: CalendarFilterInterface[]): string[] => items.map(({ _id }) => _id);

export const useAppointmentEvents = ({ calendarDate }: { calendarDate?: MbscPageLoadingEvent }) => {
  const { selectedClinicians, selectedRooms, selectedHighLights } = useAppSelector(calendarFilters);
  const appointmentCollection = useAppSelector(calendarAppointmentList);
  const { auth0ClinicianId } = useGetClinicianId();
  const { accountTimeZone } = useTimeZone();
  const { clinicianProfile } = useGetAccountInfo();
  const { practitionersList: practitionerFullList, roomList: roomFullList } = useFetchFilterList();

  const clinicianIds = useMemo(() => getIds(selectedClinicians), [selectedClinicians]);
  const roomIds = useMemo(() => getIds(selectedRooms), [selectedRooms]);

  const from = useMemo(
    () => momentTz(calendarDate?.firstDay).subtract(FETCH_NUMBER_OF_DAYS, 'days').format('YYYY-MM-DD'),
    [calendarDate]
  );
  const to = useMemo(
    () =>
      momentTz(calendarDate?.firstDay)
        .add(FETCH_NUMBER_OF_DAYS + 6, 'days')
        .format('YYYY-MM-DD'),
    [calendarDate]
  );

  const appointmentsWithFilter = useMemo((): AppointmentSlots[] => {
    const filteredAppointments = [
      ...(roomIds || [])
        .concat(clinicianIds || [])
        .map((i) => appointmentCollection[i])
        .concat(clinicianIds?.includes('') ? [appointmentCollection[auth0ClinicianId]] : [])
        .filter((i) => i)
        .flatMap((obj) => Object.values(obj).flat())
    ];
    return filteredAppointments.filter(
      (item, index) => index === filteredAppointments.findIndex((i) => i._id === item._id)
    );
  }, [appointmentCollection, auth0ClinicianId, clinicianIds, roomIds]);

  const getGroup = useCallback(
    (appointment: AppointmentSlots) => {
      let result;
      if (appointment.isRoomFilter) {
        result = selectedRooms.find((filter) => {
          return filter._id === appointment?.room?.roomId;
        })?.color;
      } else if (appointment.groupId) {
        const eventOwnerColor = selectedClinicians.find(
          (filter) => appointment.clinicianId && appointment.clinicianId === filter._id
        )?.color;
        const practiceColor = selectedClinicians.find((filter) => filter._id === '')?.color;
        const groupClinicianColor = selectedClinicians.find(
          (filter) =>
            appointment.group?.leadClinicianAuth0Id === filter._id ||
            appointment.group?.clinicianAuth0Ids.filter((clinicianId: string) => clinicianId === filter._id).length > 0
        )?.color;
        result = eventOwnerColor || practiceColor || groupClinicianColor;
      } else {
        result = selectedClinicians.find((filter) => {
          return filter._id === appointment.clinicianId || filter._id === appointment.accountId;
        })?.color;
      }
      return result;
    },
    [selectedClinicians, selectedRooms]
  );

  const getAppointmentHostProfile = useCallback(
    (appointment: AppointmentSlots) => ({
      ...(appointment.clinicianProfile
        ? {
            name: appointment.clinicianProfile.name,
            avatar: appointment.clinicianProfile.avatar
          }
        : appointment.group
        ? {
            name: appointment.group.name,
            icon: 'groups'
          }
        : clinicianProfile?.practice
        ? {
            name: clinicianProfile.practice.name,
            avatar: clinicianProfile.practice.logo
          }
        : {})
    }),
    [clinicianProfile?.practice]
  );

  const formattedEvents = useMemo(() => {
    const formatEvents: CustomCalendarEvent[] =
      appointmentsWithFilter?.flatMap((appointmentItem, index) => {
        const group = getGroup(appointmentItem);

        const event = {
          key: `${appointmentItem._id}`,
          id: `${appointmentItem._id}_${index}`,
          start:
            appointmentItem.startDateTime ||
            getTimeZoneDateTime(`${appointmentItem.date} ${appointmentItem.startTime}`, accountTimeZone).utc(),
          end:
            appointmentItem.endDateTime ||
            getTimeZoneDateTime(`${appointmentItem.date} ${appointmentItem.endTime}`, accountTimeZone).utc(),
          title: appointmentItem.title,
          profile: appointmentItem?.clientRecord?.clientProfiles?.[0]!,
          clientRecord: appointmentItem?.clientRecord,
          deliveryType: appointmentItem.deliveryType,
          otherInstructions: appointmentItem.otherInstructions,
          highLights: [],
          group: group || '',
          room: appointmentItem.room?.roomName,
          profileName: appointmentItem.integration?.profileName,
          type: appointmentItem.type,
          groupId: appointmentItem.groupId,
          groupTitle:
            appointmentItem.group?.name || (appointmentItem.isRoomBookedByOthers && clinicianProfile?.practice?.name),
          gap: appointmentItem.gap || 0,
          status: appointmentItem.markedStatus,
          isRecurring: appointmentItem.recurrings?.length > 0,
          recurrings: appointmentItem.recurrings || [],
          recurringIndex: appointmentItem.recurrings?.findIndex((i) => i.date === appointmentItem.date) || -1,
          sessionTypeId: appointmentItem.sessionTypeId,
          clinicianId: appointmentItem.clinicianId || 'practiceId',
          resource: appointmentItem.clinicianId || 'practiceId',
          isCancelled: appointmentItem.isCancelled || false,
          invoices: appointmentItem.invoices || [],
          isRoomBookedByOthers: appointmentItem.isRoomBookedByOthers,
          hostProfile: getAppointmentHostProfile(appointmentItem),
          isProcessed: appointmentItem?.isProcessed,
          requestedChanges: appointmentItem?.requestedChanges,
          serviceDelivered: appointmentItem.serviceDelivered,
          paymentRequestIds: appointmentItem.paymentRequestIds,
          isActivity: appointmentItem.isActivity,
          ...(appointmentItem.packageId && {
            packageId: appointmentItem.packageId,
            packageAssigneeId: appointmentItem.packageAssigneeId
          })
        };

        return appointmentItem.isRoomFilter && appointmentItem.room
          ? [event, { ...event, resource: appointmentItem.room.roomId, isRoomFilter: true }]
          : [event];
      }) || [];

    return highLightEvents(selectedHighLights, formatEvents);
  }, [
    appointmentsWithFilter,
    selectedHighLights,
    accountTimeZone,
    clinicianProfile?.practice,
    getAppointmentHostProfile,
    getGroup
  ]);

  const clinicianCompleteListWithBatch = useMemo(
    () => chunkArray(getIds(practitionerFullList), BATCH_CHUNK_SIZE),
    [practitionerFullList]
  );
  const roomCompleteListWithBatch = useMemo(() => chunkArray(getIds(roomFullList), BATCH_CHUNK_SIZE), [roomFullList]);

  const clinicianIdChunks = useMemo(
    () => rearrangeSelectedChunks(clinicianCompleteListWithBatch, clinicianIds),
    [clinicianIds, clinicianCompleteListWithBatch]
  );

  const roomIdChunks = useMemo(
    () => rearrangeSelectedChunks(roomCompleteListWithBatch, roomIds),
    [roomIds, roomCompleteListWithBatch]
  );

  const debouncedFrom = useDebounce(from, 300);
  const debouncedTo = useDebounce(to, 300);
  const debouncedClinicianIds = useDebounce(clinicianIds, 300);
  const debouncedRoomIds = useDebounce(roomIds, 300);
  const debouncedClinicianIdBatches = useDebounce(clinicianIdChunks, 300);
  const debouncedRoomIdBatches = useDebounce(roomIdChunks, 300);

  const { isLoading: currentWeekLoading, isFetching: currentWeekFetching } = useAppointmentsEventByBatch({
    from: debouncedFrom,
    to: debouncedTo,
    clinicianIds: debouncedClinicianIds,
    roomIds: debouncedRoomIds,
    clinicianIdBatches: debouncedClinicianIdBatches,
    roomIdBatches: debouncedRoomIdBatches
  });

  return {
    appointmentsWithFilter,
    events: formattedEvents,
    isLoading: currentWeekLoading,
    isFetching: currentWeekFetching
  };
};
