import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { useAppDispatch } from 'redux/hooks';
import { useCallback, useEffect, useState } from 'react';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import {
  appointmentApiSlice,
  useGetAppointmentsByClinicianIdsQuery
} from 'redux/endpoints/scheduleServices/appointment';
import { BATCH_CHUNK_SIZE } from './useAppointmentEvents';
import { updateAppointments } from 'redux/calendarAppointmentList/calendarAppointmentListDataSlice';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';

const REFETCH_EVERY_MINUTES = 5;
/**
 * Custom hook for fetching appointments based on clinician and room IDs.
 *
 * This hook decides whether to batch requests based on the number of IDs. If batching is needed,
 * it fetches appointments in batches. If not, it fetches them directly. It returns the appointment data,
 * and loading and fetching states.
 */

interface appointmentsEventByBatchInterface {
  from: string;
  to: string;
  clinicianIds: string[];
  roomIds: string[];
  clinicianIdBatches: string[][];
  roomIdBatches: string[][];
}

export const useAppointmentsEventByBatch = ({
  from,
  to,
  clinicianIds,
  roomIds,
  clinicianIdBatches,
  roomIdBatches
}: appointmentsEventByBatchInterface) => {
  const { accountId } = useGetAccountId();
  const { isEdgeUserView } = useGetAccountPackageView();
  const { accountTimeZone } = useTimeZone();
  const { auth0ClinicianId } = useGetClinicianId();
  const dispatch = useAppDispatch();

  const totalClinicianAndRoomIds = clinicianIds.length + roomIds.length;
  const shouldBatchFetch = totalClinicianAndRoomIds >= BATCH_CHUNK_SIZE;

  const [data, setData] = useState<AppointmentSlots[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAppointmentsSequentially = useCallback(
    async (batches: string[][], type: 'clinician' | 'room') => {
      for (const batch of batches) {
        try {
          const result = await dispatch(
            appointmentApiSlice.endpoints.getAppointmentsByClinicianIds.initiate(
              {
                accountId,
                asUser: isEdgeUserView,
                params: {
                  from,
                  to,
                  [`${type}Ids`]: batch.join(',')
                },
                timeZone: accountTimeZone
              },
              { subscriptionOptions: { pollingInterval: 1000 * 60 * REFETCH_EVERY_MINUTES } }
            )
          ).unwrap();

          setData((prevData) => [...prevData, ...result]);
          dispatch(updateAppointments({ appts: result, from, to, roomIds, clinicianIds, auth0ClinicianId }));
        } catch (error) {
          console.error(`Error fetching ${type} appointments:`, error);
        }
      }
    },
    [dispatch, from, to, roomIds, clinicianIds, accountId, isEdgeUserView, accountTimeZone, auth0ClinicianId]
  );

  useEffect(() => {
    const fetchAndUpdate = async () => {
      if (shouldBatchFetch) {
        setIsLoading(true);
        try {
          await fetchAppointmentsSequentially(clinicianIdBatches, 'clinician');
          await fetchAppointmentsSequentially(roomIdBatches, 'room');
        } catch (error) {
          console.error('Error fetching appointments:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchAndUpdate();
  }, [
    dispatch,
    clinicianIdBatches,
    roomIdBatches,
    from,
    to,
    shouldBatchFetch,
    clinicianIds,
    roomIds,
    fetchAppointmentsSequentially
  ]);

  const {
    data: notBatchAppointmentData,
    isLoading: notBatchAppointmentLoading,
    isFetching: notBatchAppointmentFetching
  } = useGetAppointmentsByClinicianIdsQuery(
    {
      accountId,
      asUser: isEdgeUserView,
      params: {
        from,
        to,
        clinicianIds: clinicianIds.join(','),
        roomIds: roomIds.join(',')
      },
      timeZone: accountTimeZone
    },
    {
      skip: clinicianIds.length <= 0 || shouldBatchFetch,
      pollingInterval: 1000 * 60 * REFETCH_EVERY_MINUTES
    }
  );

  useEffect(() => {
    if (!shouldBatchFetch && notBatchAppointmentData) {
      dispatch(
        updateAppointments({ appts: notBatchAppointmentData, from, to, roomIds, clinicianIds, auth0ClinicianId })
      );
    }
  }, [notBatchAppointmentData, shouldBatchFetch, from, to, roomIds, clinicianIds, dispatch, auth0ClinicianId]);

  return {
    data: shouldBatchFetch ? data : notBatchAppointmentData,
    isLoading: shouldBatchFetch ? isLoading : notBatchAppointmentLoading,
    isFetching: shouldBatchFetch ? isLoading : notBatchAppointmentFetching
  };
};
