import { MbscCalendarEvent, MbscCalendarEventData } from '@mobiscroll/react';
import styles from './CalendarEvent.module.scss';
import classnames from 'classnames';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { DeliveryType, DELIVERY_TYPE_ICONS, OtherInstructions } from 'interfaces/Schedule/AppointmentType';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import moment from 'moment';
import colorBoxBackgroundStyles from '../../../CalendarFilterSection/components/CalendarFilterCheckList/components/FilterColorBox/colorBoxBackground.module.scss';
import { AppointmentSlots } from 'interfaces/Schedule/Appointment';
import calendarEventGroupColorStyles from './calendarEventGroupColor.module.scss';
import { Popover } from 'antd';
import EventDetailsTooltip from './components/EventDetailsTooltip/EventDetailsTooltip';
import { MouseEventHandler } from 'react';
import { CalendarFilter } from 'components/v2/CalendarFilter/interfaces';
import classNames from 'classnames';
import { getDeliveryTypeLabel } from 'utils/appointment';

type Profile = {
  _id: string;
  clientAuth0Id?: string;
  avatar?: string;
  initials?: string;
  initialsBackgroundColor?: string;
  name: string;
};

export type CustomCalendarEvent = MbscCalendarEvent & {
  clientRecord?: clientRecordsInterface;
  profile?: Profile;
  deliveryType?: string;
  otherInstructions?: OtherInstructions;
  group?: string;
  groupId?: string;
  room?: string;
  profileName?: string;
  type?: string;
  gap?: number;
  highLights?: CalendarFilter['highLightList'];
  status?: AppointmentSlots['markedStatus'];
  isRecurring?: boolean;
  recurringIndex?: number;
  recurrings?: AppointmentSlots['recurrings'];
  sessionTypeId?: AppointmentSlots['sessionTypeId'];
  clinicianId?: string;
  isRoomFilter?: boolean;
  isCancelled?: boolean;
  invoices?: AppointmentSlots['invoices'];
  isProcessed?: boolean;
  requestedChanges?: AppointmentSlots['requestedChanges'];
  isActivity?: boolean;
  serviceDelivered?: AppointmentSlots['serviceDelivered'];
  resource?: string;
};

type CalendarEventProps = {
  data: MbscCalendarEventData & {
    original: CustomCalendarEvent;
    appointmentTypes: CalendarFilter['highLightList'];
    practitionersList: CalendarFilter['practitionersList'];
  };
  is2xZoom: boolean;
  isHorizontalCalendar: boolean;
  onEditEvent: MouseEventHandler;
};

const getDeliveryTypeProps = (deliveryType?: string, otherInstructions?: OtherInstructions) => {
  if (deliveryType) {
    return {
      text: getDeliveryTypeLabel(deliveryType, otherInstructions),
      icon: <span className="material-icons-outlined">{DELIVERY_TYPE_ICONS[deliveryType as DeliveryType]}</span>
    };
  }

  return { text: '', icon: null };
};

const CalendarEvent = ({ data, is2xZoom, isHorizontalCalendar, onEditEvent }: CalendarEventProps) => {
  // render hover state placeholder when the inline booking form show up
  if (data.original.type === 'hover-state') {
    return (
      <div
        className={classnames(
          styles.hoverStateContainer,
          is2xZoom && styles.zoom2x,
          isHorizontalCalendar && styles.horizontalHover
        )}
      />
    );
  }

  const {
    clientRecord,
    deliveryType,
    otherInstructions,
    group,
    groupId,
    groupTitle,
    room,
    type,
    profileName,
    highLights,
    isRoomBookedByOthers,
    hostProfile,
    isActivity
  } = data.original;

  const colourTags =
    highLights
      ?.map((i) => i.color)
      ?.filter((item, index, arr) => arr.findIndex((i) => i === item) === index)
      ?.slice(0, 4) || [];

  const deliveryTypeProps = getDeliveryTypeProps(deliveryType, otherInstructions);

  const SelectClientProfiles =
    clientRecord?.recordType === 'child'
      ? clientRecord?.clientProfiles.filter((profileType) => profileType.role === 'child')
      : clientRecord?.clientProfiles;

  const eventPeriod = moment.duration(moment(data.end, 'hh:mmA').diff(moment(data.start, 'hh:mmA'))).asMinutes();
  const isShortTime = moment(data.end, 'hh:mmA').diff(moment(data.start, 'hh:mmA')) < 50 * 60 * 1000;

  const less5Minute = eventPeriod <= 5;
  const less10Minute = eventPeriod >= 6 && eventPeriod <= 10;

  const renderOneToOneEventCard = () => (
    <div className={styles.details}>
      {isRoomBookedByOthers ? (
        <div>{hostProfile.name}</div>
      ) : (
        clientRecord?.clientProfiles && (
          <div className={styles.clientNameContainer}>
            <ClientAvatar
              clientData={SelectClientProfiles}
              avatarSize={30}
              displayFirstNameOnly={clientRecord?.recordType === 'couple'}
              displayLimit={clientRecord?.recordType === 'couple' ? 2 : 1}
              horizontal
              initialsClassName={styles.initialName}
              nameClassName={classnames(
                styles.clientName,
                less5Minute && styles.smallClientName,
                less10Minute && styles.mediumClientName
              )}
              overlayCircle
              hideAvatar={isShortTime}
            />
            {data.original.type === 'reserved' && !data.title?.includes('Reserved Slot') && (
              <span className={styles.reservedText}>(Reserved)</span>
            )}
          </div>
        )
      )}

      <div className={styles.title}>{data.title}</div>

      {!less5Minute && !less10Minute && (
        <>
          {type && ['free', 'busy'].includes(type) ? (
            <div>{profileName}</div>
          ) : (
            <div className={styles.time}>
              {data.start} - {data.end}
            </div>
          )}
          {room && <div className={styles.room}>{room}</div>}
          {isRoomBookedByOthers && <i className={classNames(styles.icon, 'material-icons')}>meeting_room</i>}
          {!isRoomBookedByOthers && colourTags.length <= 0 && deliveryTypeProps.icon && (
            <div className={styles.icon}>{deliveryTypeProps.icon}</div>
          )}
          {colourTags && (
            <div
              className={classnames(
                styles.colourTags,
                colourTags.length > 1 && eventPeriod <= 20 && styles.shortColourTags
              )}
            >
              {colourTags.map((i, index) => (
                <div key={index} className={classnames(styles.colourTag, colorBoxBackgroundStyles[i])} />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );

  const renderGroupEventCard = () => (
    <div className={styles.groupCard}>
      <div className={styles.groupCardHeading}>
        <i className={`material-icons ${styles.groupIcon}`}>groups</i>
        <div className={styles.groupName}>{groupTitle}</div>
      </div>
      {!less5Minute && !less10Minute && (
        <>
          <div className={styles.time}>
            {data.start} - {data.end}
          </div>
          {room && <div className={styles.room}>{room}</div>}
          {colourTags.length <= 0 && deliveryTypeProps.icon && (
            <div className={styles.icon}>{deliveryTypeProps.icon}</div>
          )}
          {colourTags && (
            <div
              className={classnames(
                styles.colourTags,
                colourTags.length > 1 && eventPeriod <= 20 && styles.shortColourTags
              )}
            >
              {colourTags.map((i, index) => (
                <div key={index} className={classnames(styles.colourTag, colorBoxBackgroundStyles[i])} />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );

  const isTacklitEvent =
    type && ['free', 'busy', 'reserved', 'otherWorkspace'].includes(type) && styles[type] && !isActivity;

  return (
    <Popover
      title={!isTacklitEvent && <EventDetailsTooltip data={data} onEditEvent={onEditEvent} />}
      trigger={'hover'}
      overlayClassName={!isTacklitEvent ? styles.popover : styles.popoverNone}
      placement={eventPeriod > 60 ? 'left' : 'bottom'}
    >
      <div
        className={classnames(
          data.original.isCancelled ? styles.cancelledContainer : styles.container,
          !data.original.isCancelled && (calendarEventGroupColorStyles[`group-${group}`] || undefined),
          isTacklitEvent && styles[type],
          isShortTime ? styles.shortTime : '',
          colourTags.length === 1 && styles.oneTag,
          colourTags.length === 0 && styles.noTag,
          is2xZoom && styles.zoom2x,
          isHorizontalCalendar && styles.horizontalView,
          data.original.type === 'reserved' && styles.reservedAppointment
        )}
      >
        <div className={classnames(styles.body, (less5Minute || less10Minute) && styles.bodyShortHorizontal)}>
          {groupId ? renderGroupEventCard() : renderOneToOneEventCard()}
        </div>
      </div>
    </Popover>
  );
};

export default CalendarEvent;
