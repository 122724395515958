import { MbscCalendarEventData } from '@mobiscroll/react';
import classNames from 'classnames';
import ClientAvatar from 'components/ClientAvatar/ClientAvatar';
import { CustomCalendarEvent } from '../../CalendarEvent';
import styles from './EventDetailsTooltip.module.scss';
import colorBoxStyles from '../../../../../CalendarFilterSection/components/CalendarFilterCheckList/components/FilterColorBox/colorBoxBackground.module.scss';
import { DeliveryType, AppointmentType } from 'interfaces/Schedule/AppointmentType';
import { CalendarFilterSectionPropsT23 } from 'components/v2/CalendarFilter/interfaces';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { MouseEventHandler, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoutesGenerator } from 'utils/hooks/Path/RoutesGenerator';
import { isColorBlindColour } from '../../../../../CalendarFilterSection/components/CalendarFilterCheckList/components/FilterColorBox/FilterColorBox';
import ContactDetails from 'pages/Groups/GroupDetails/components/GroupContent/components/Common/GroupConfigurationActionMenu/components/ContactDetails/ContactDetails';
import { RecordStatus } from 'interfaces/Clients/clientsRecord';
import { useGetNextAppointmentsQuery } from 'redux/endpoints/scheduleServices/appointment';
import { Skeleton } from 'antd';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import { convertUtcTimeToClinicianTime } from 'utils/helpers/timezone';
import moment from 'moment';
import { useAppDispatch } from 'redux/hooks';
import { setPreSelectedClientRecordId } from 'redux/features/messages/broadcastMessageSlice';
import HelpOutLineWithTooltips from 'components/HelpOutLineWithTooltips/HelpOutLineWithTooltips';
import ListWithShowMore from 'components/ListWithShowMore/ListWithShowMore';
import { useTranslation } from 'react-i18next';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import IconTag from 'components/IconTag/IconTag';
import { getDeliveryTypeLabel } from 'utils/appointment';
import { ServiceDeliveredNameMapping } from 'interfaces/Schedule/Appointment';
import { useGetProfileTagsQuery } from 'redux/endpoints/clinicianProfileServices/profileTag';
import { useGetPackageBookableAppointmentQuery } from 'redux/endpoints/scheduleServices/package';

type MenuItem = {
  icon: string;
  title: string;
  onClick: MouseEventHandler;
  disabled?: boolean;
  tooltip?: ReactNode;
  iconElement?: ReactNode;
};

// eslint-disable-next-line complexity
const EventDetailsTooltip = ({
  data,
  onEditEvent
}: {
  data: MbscCalendarEventData & {
    original: CustomCalendarEvent;
    appointmentTypes: CalendarFilterSectionPropsT23['filterList']['highLightList'];
    practitionersList: CalendarFilterSectionPropsT23['filterList']['practitionersList'];
  };
  onEditEvent: MouseEventHandler;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { accountId } = useGetAccountId();
  const { timeZoneByView } = useTimeZone();
  const { LETTERS, CLIENTS, CARECOORDINATION, MESSAGES, INVOICES } = useRoutesGenerator();
  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();

  const [showMenuDot, setShowMenuDot] = useState<boolean>(false);
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);

  const menuNode = useRef<HTMLDivElement>(null);
  const subMenuNode = useRef<HTMLDivElement>(null);

  const {
    key,
    clientRecord,
    invoices,
    deliveryType,
    otherInstructions,
    group,
    groupId,
    room,
    status,
    highLights,
    start,
    isRoomBookedByOthers,
    hostProfile,
    isProcessed,
    requestedChanges,
    serviceDelivered,
    isActivity,
    packageId,
    packageAssigneeId
  } = data.original;

  const { data: nextAppointments, isLoading: isNextAppointmentsLoading } = useGetNextAppointmentsQuery(
    {
      accountId,
      clientRecordId: clientRecord?._id || '',
      isAdmin: isEdgeAdminView || isEdgeReceptionist
    },
    { skip: !clientRecord?._id }
  );

  const {
    data: profileTags,
    isLoading: isProfileTagsLoading,
    isFetching: isProfileTagsFetching
  } = useGetProfileTagsQuery({
    accountId
  });

  const { data: assignmentPackageList, isLoading: isAssignmentPackageListLoading } =
    useGetPackageBookableAppointmentQuery(
      {
        accountId: accountId,
        clientRecordId: clientRecord?._id || ''
      },
      { skip: !clientRecord }
    );

  const getPackageDetails = useMemo(
    () => assignmentPackageList?.find((aPackageObj) => aPackageObj.assignee._id === packageAssigneeId),
    [assignmentPackageList, packageAssigneeId]
  );

  const appointmentInvoices = useMemo(() => {
    return (
      invoices &&
      invoices.length > 0 &&
      invoices
        .map((invoice) => {
          return {
            name: invoice.invoiceId || invoice._id,
            _id: invoice._id,
            status: invoice.status,
            onClick: () => navigate(`${INVOICES.BASE}/${invoice._id}`)
          };
        })
        .reverse()
    );
  }, [invoices, navigate, INVOICES.BASE]);

  const clientRecordTags = useMemo(() => {
    return !isProfileTagsLoading && !isProfileTagsFetching && profileTags?.length && clientRecord?.tags
      ? clientRecord.tags.flatMap((tagId) => profileTags.find((item) => item._id === tagId) || [])
      : [];
  }, [profileTags, isProfileTagsFetching, isProfileTagsLoading, clientRecord?.tags]);

  const handleClickOutSide = (event: any) => {
    if (menuNode.current?.contains(event.target) || subMenuNode.current?.contains(event.target)) {
      return;
    }
    setShowSubMenu(false);
    setShowMenuDot(false);
  };

  useEffect(() => {
    setShowSubMenu(false);
    setShowMenuDot(false);
  }, [data]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const menuItems = useMemo(() => {
    const onClickSendSMS = () => {
      if (
        clientRecord?._id &&
        clientRecord?.recordStatus !== RecordStatus.Closed &&
        clientRecord?.allowCommunicationWithSms
      ) {
        /**
         * Set preselect client record id
         * Will be used in BroadcastClientList, to fetch client record data
         */
        dispatch(setPreSelectedClientRecordId(clientRecord._id));
        navigate(MESSAGES.BASE);
      }
    };

    const items: MenuItem[] = [];
    if (!groupId) {
      items.push({
        icon: 'person_search',
        title: 'View Client Profile',
        onClick: () => {
          if (clientRecord?._id) {
            navigate(`${CLIENTS.BASE}/${clientRecord._id}/profile`);
          }
        },
        disabled: !clientRecord?._id
      });

      if (!data.original.isCancelled && !isProcessed) {
        items.push({
          icon: 'schedule',
          title: 'Edit Appointment',
          onClick: onEditEvent
        });
      }

      if (!isActivity) {
        if (!isEdgeReceptionist) {
          items.push({
            icon: 'edit_note',
            title: 'Add Progress Notes',
            onClick: () => {
              clientRecord?._id &&
                clientRecord?.recordStatus !== RecordStatus.Closed &&
                navigate(`${CLIENTS.BASE}/${clientRecord._id}/notes`);
            },
            disabled: !clientRecord?._id || clientRecord?.recordStatus === RecordStatus.Closed,
            tooltip: clientRecord?.recordStatus === RecordStatus.Closed && (
              <HelpOutLineWithTooltips id={`clientIsClosed`} desc="This client is closed." />
            )
          });
        }

        items.push({
          icon: 'chat',
          iconElement: (
            <div className={styles.menuItemIcon}>
              <FontAwesomeIcon icon={faCommentDots} className={styles.icon} />
            </div>
          ),
          title: 'Send SMS',
          onClick: onClickSendSMS,
          disabled:
            !clientRecord?._id ||
            clientRecord?.recordStatus === RecordStatus.Closed ||
            !clientRecord?.allowCommunicationWithSms,
          tooltip:
            clientRecord &&
            (clientRecord.recordStatus === RecordStatus.Closed ? (
              <HelpOutLineWithTooltips id={`clientIsClosed`} desc="This client is closed." />
            ) : (
              !clientRecord.allowCommunicationWithSms && (
                <HelpOutLineWithTooltips
                  id={`noAllowedSendSMS`}
                  desc={t('form.error.broadcast_client_limited_communication')}
                />
              )
            ))
        });

        items.push({
          icon: 'mail',
          title: 'Write Letter',
          disabled: !clientRecord?._id || clientRecord?.recordStatus === RecordStatus.Closed,
          onClick: () => {
            clientRecord?._id &&
              clientRecord?.recordStatus !== RecordStatus.Closed &&
              navigate(`${LETTERS.NEW}?clientRecordId=${clientRecord._id}`);
          }
        });
      }

      items.push({
        icon: 'chat',
        title: 'Client Contact Info',
        onClick: () => {
          if (!clientRecord?._id) {
            return;
          }
          setShowSubMenu(true);
        },
        disabled: !clientRecord?._id
      });
    } else {
      items.push(
        {
          icon: 'person_search',
          title: 'View Group Info',
          onClick: () => navigate(`${CARECOORDINATION.GROUPS}/${groupId}/details`)
        },
        {
          icon: 'schedule',
          title: 'Edit Appointment',
          onClick: onEditEvent
        }
      );
    }
    return items;
  }, [
    CLIENTS.BASE,
    CARECOORDINATION.GROUPS,
    LETTERS.NEW,
    MESSAGES.BASE,
    clientRecord,
    data.original.isCancelled,
    groupId,
    isActivity,
    isEdgeReceptionist,
    isProcessed,
    navigate,
    onEditEvent,
    t,
    dispatch
  ]);

  const renderHeaderMenu = () => (
    <div className={styles.menu}>
      {menuItems.map((item, index) => (
        <div
          className={classNames(styles.menuItem, item.disabled ? styles.disabled : '')}
          key={index}
          onClick={item.onClick}
        >
          <div className={styles.menuItemTitle}>
            {item.iconElement ? (
              item.iconElement
            ) : (
              <i className={classNames(styles.menuItemIcon, 'material-icons-outlined')}>{item.icon}</i>
            )}
            {item.title}
            {item.tooltip}
          </div>
          <i className={classNames(styles.arrowRightIcon, 'material-icons-outlined')}>navigate_next</i>
        </div>
      ))}
    </div>
  );

  const renderDetailItem = (text: JSX.Element | string, icon?: string, avatar?: JSX.Element) => (
    <div className={styles.detailItem}>
      {avatar && <div className={classNames(styles.detailItemIcon)}>{avatar}</div>}
      {icon && <i className={classNames(styles.detailItemIcon, 'material-icons-outlined')}>{icon}</i>}
      <span>{text}</span>
    </div>
  );

  const renderAppointmentType = (sessionType: AppointmentType) => {
    return (
      <div className={styles.textWithDescription}>
        <div className={styles.text}>
          {`${sessionType.name || '-'} `}
          {`${
            data.original.recurringIndex && data.original.recurringIndex >= 0 ? data.original.recurringIndex + 1 : ''
          }`}
        </div>
        <div className={styles.description}>{data.original.recurrings?.length}</div>
      </div>
    );
  };

  const renderClientProfile = () => {
    return (
      selectClientProfiles &&
      selectClientProfiles.length > 0 &&
      renderDetailItem(
        <ClientAvatar
          clientData={selectClientProfiles}
          avatarSize={24}
          nameClassName={styles.clientName}
          horizontal
          initialsClassName={styles.initialName}
          profileTags={clientRecordTags}
          containerClassName={styles.avatarContainer}
          pinnedNoteFlags={clientRecord?.activePinnedNotes}
        />,
        undefined
      )
    );
  };

  const renderPractitionerProfile = () => {
    return renderDetailItem(
      practitioner?.name || 'Undefined staff',
      undefined,
      <img src={practitioner?.avatar} className={styles.practiceLogo} alt="" />
    );
  };

  const renderHostProfile = () => {
    const avatar = hostProfile.avatar && <img src={hostProfile.avatar} className={styles.practiceLogo} alt="" />;
    return renderDetailItem(hostProfile.name, hostProfile.icon, avatar);
  };

  const selectClientProfiles =
    clientRecord?.recordType === 'child'
      ? clientRecord?.clientProfiles.filter((profileType) => profileType.role === 'child')
      : clientRecord?.clientProfiles;
  const appointmentType = data.appointmentTypes.find((i) => i._id === data.original.sessionTypeId);

  const practitioner = data.practitionersList.find((i) => i._id === data.original.clinicianId);

  const colorGroup = isColorBlindColour(group || '') ? 'colorBlindBg' : group;

  return (
    <div
      className={styles.modal}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className={classNames(
          styles.header,
          !data.original.isCancelled && (colorBoxStyles[colorGroup || ''] || undefined),
          data.original.isCancelled ? styles.cancelledItem : ''
        )}
      >
        <div className={styles.title}>
          {data.start} - {data.end}
        </div>
        {!isRoomBookedByOthers && (
          <div ref={menuNode} className={styles.menuDot} onClick={() => setShowMenuDot(true)}>
            <i className={classNames(styles.menuIcon, 'material-icons-outlined')}>more_vert</i>
            <div className={classNames(styles.menuWrapper, showMenuDot ? styles.show : '')}>
              {renderHeaderMenu()}
              <div ref={subMenuNode} className={showSubMenu ? styles.subMenuShow : styles.subMenuHide}>
                {showSubMenu && (
                  <ContactDetails clientRecordId={data.original.clientRecord?._id || ''} visible={showSubMenu} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.body}>
        {!isRoomBookedByOthers && <div className={styles.appointmentID}>ApptID: {key}</div>}

        {!isRoomBookedByOthers && renderClientProfile()}

        {isEdgeAdminView && renderPractitionerProfile()}

        {isRoomBookedByOthers && renderHostProfile()}

        {isRoomBookedByOthers && appointmentType?.name && renderDetailItem(appointmentType?.name, 'calendar_today')}

        {packageId && getPackageDetails && (
          <div className={styles.detailItem}>
            <i className={classNames(styles.detailItemIcon, 'material-symbols-outlined')}>contract</i>
            {isAssignmentPackageListLoading ? (
              <Skeleton.Input active className={styles.packageLoading} />
            ) : (
              <div>
                <div>
                  {getPackageDetails.name} | {getPackageDetails.assignee.funder.name}
                </div>
                <div className={styles.detailDesc}>{getPackageDetails.description}</div>
              </div>
            )}
          </div>
        )}

        {!isRoomBookedByOthers &&
          appointmentType?.sessionType &&
          renderDetailItem(
            data.original.recurrings && data.original.recurrings.length > 0
              ? renderAppointmentType(appointmentType?.sessionType)
              : appointmentType.sessionType.name,
            'event'
          )}

        {room && renderDetailItem(room, 'meeting_room')}

        {deliveryType && renderDetailItem(getDeliveryTypeLabel(deliveryType, otherInstructions), 'group')}

        {!isRoomBookedByOthers &&
          status &&
          status.length > 0 &&
          renderDetailItem(
            <div className={styles.statusList}>
              {status.map((item, index) => (
                <div className={styles.statusItem} key={index}>
                  {item}
                </div>
              ))}
            </div>,
            'label'
          )}

        {highLights &&
          highLights.length > 0 &&
          renderDetailItem(
            <div className={styles.highLightList}>
              {highLights.map((item, index) => {
                const colorGroup = isColorBlindColour(item.color) ? 'colorBlindBg' : item.color;
                return (
                  <div className={classNames(styles.highLightItem, colorBoxStyles[colorGroup])} key={index}>
                    {item.name}
                  </div>
                );
              })}
            </div>,
            'flare'
          )}

        {!isRoomBookedByOthers &&
          appointmentInvoices &&
          renderDetailItem(
            <div className="flex">
              <ListWithShowMore
                initialLength={1}
                list={appointmentInvoices}
                afterTextDisplay={(invoiceId: string) => {
                  const invoice = appointmentInvoices.find((inv) => inv._id === invoiceId);
                  return invoice?.status === 'confirmPaid' ? <div className={styles.pill}>PAID</div> : null;
                }}
              />
            </div>,
            'receipt'
          )}
        {/* Next Appointment */}
        {!isRoomBookedByOthers &&
          !groupId &&
          deliveryType !== DeliveryType.Bespoke &&
          renderDetailItem(
            <div className={styles.nextAppointment}>
              Next Appointment
              {isNextAppointmentsLoading ? (
                <Skeleton.Input active className={styles.loading} />
              ) : (
                <div className={styles.nextAppointmentDetails}>
                  {(() => {
                    if (nextAppointments && nextAppointments.length) {
                      const nextAppointment = nextAppointments.find(
                        (item) =>
                          moment(item.startDateTime).isAfter(moment()) &&
                          moment(item.startDateTime).isAfter(moment(start))
                      );
                      if (nextAppointment && nextAppointment.startDateTime && nextAppointment.endDateTime) {
                        const practitioner = data.practitionersList.find(
                          (item) => item._id === nextAppointment.clinicianId
                        );

                        const { date, startTime, endTime } = convertUtcTimeToClinicianTime({
                          startDateTime: new Date(nextAppointment.startDateTime),
                          endDateTime: new Date(nextAppointment.endDateTime),
                          timezone: timeZoneByView
                        });
                        return (
                          <>
                            <div>{`${practitioner?.title ? `${practitioner.title} ` : ''}${practitioner?.name}`}</div>
                            <div>{`${moment(date).format('DD MMMM YYYY')} ${startTime} to ${endTime}`}</div>
                          </>
                        );
                      }
                    }

                    return <div>N/A</div>;
                  })()}
                </div>
              )}
            </div>,
            'event'
          )}

        {/* Service delivered - Processed tag */}
        {isProcessed && (
          <IconTag
            tagContainerClass={styles.serviceDeliveredText}
            iconName="playlist_add_check"
            text={(serviceDelivered && ServiceDeliveredNameMapping[serviceDelivered]) || 'Processed'}
          />
        )}
        {/* Reviewing tag */}
        {!isProcessed && requestedChanges && (
          <IconTag
            tagContainerClass={styles.reviewingTag}
            iconClass={styles.reviewingIcon}
            iconName="flag_circle"
            text="Reviewing"
          />
        )}
      </div>
    </div>
  );
};

export default EventDetailsTooltip;
