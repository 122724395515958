import {
  Eventcalendar,
  MbscEventcalendarView,
  MbscPageLoadingEvent,
  MbscCellClickEvent,
  momentTimezone,
  setOptions
} from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.scss';
import { notification } from 'antd';
import RoomLabel from 'components/v2/CalendarFilter/components/RoomLabel/RoomLabel';
import { CalendarFilterInterface } from 'components/v2/CalendarFilter/interfaces';
import ProfileBadge from 'components/v2/ProfileBadge/ProfileBadge';
import { AppointmentSlots, AppointmentStatusType, ServiceDelivered } from 'interfaces/Schedule/Appointment';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import EventInformationModal from 'pages/Calendar/components/Modals/EventInformationModal/EventInformationModal';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState, useRef, CSSProperties } from 'react';
import ReactDOMClient from 'react-dom/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { getTimeZoneDateTime } from 'utils/hooks/GetTimezones/getTimezones';
import { useGetAccessToken } from 'utils/hooks/token';
import { getAppointmentById } from 'utils/http/ScheduleService/Appointments/Appointments';
import { CALENDAR_FILTER_COLOURS_VALUE } from '../CalendarFilterSection/components/CalendarFilterCheckList/components/FilterColorBox/FilterColorBox';
import styles from './CalendarView.module.scss';
import CalendarEvent, { CustomCalendarEvent } from './components/CalendarEvent/CalendarEvent';
import CalendarHeader from './components/CalendarHeader/CalendarHeader';
import StartTimeSettingControl from './components/StartTimeSettingControl/StartTimeSettingControl';
import classNames from 'classnames';
import { convertUtcTimeToClinicianTime } from 'utils/helpers/timezone';
import {
  AppointmentHumanFactorStep,
  AppointmentViewType,
  resetAppointmentData,
  selectAppointmentData,
  setAppointmentView,
  setCurrentStep,
  setIsFetchingPractitioners,
  setOriginalDateTime,
  setPractitionerMainOptions,
  setPractitionerOptions,
  setSelectedDate,
  setSelectedPractitioner,
  setSelectedTime,
  setStartValidation
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import InlineBookingModal from './components/InlineBookingModal/InlineBookingModal';
import {
  TOP_LEFT_OF_SCHEDULE_CALENDAR_SELECTOR,
  SCHEDULE_ITEM_CLASS,
  FIRST_HALF_HOUR_CLASS,
  HALF_PAST_HOUR_CLASS,
  MARK_TO_APPT_CREATION_CLASS,
  DEFAULT_APPT_RANGE,
  AUTO_START_VALUE,
  convertDateWithTimezone,
  calculateInlineModalPosition
} from './utils';
import { useGetAccountInfo } from 'utils/hooks/GetAccountInfo/getAccountInfo';
import { useWindowSize } from 'utils/useWindowSize';
import { resetProcessAppointment } from 'redux/processAppointment/processAppointmentSlice';
import { useFetchFilterList } from '../CalendarFilterSection/hooks/GetFilterList';
import { scheduleServicesApiSlice, SSTagTypes } from 'redux/services/scheduleServicesApiSlice';
import { calendarFilters } from 'redux/calendarAppointmentList/calendarAppointmentListDataSlice';

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000; // 24hr in ms
const TIME_CELL_STEP = 60;

setOptions({
  theme: 'material',
  themeVariant: 'light'
});

interface CalendarViewProps {
  timezone: string;
  selectedCalendarDate?: Date;
  setSelectedCalendarDate: (v?: Date) => void;
  calendarDate?: MbscPageLoadingEvent;
  setCalendarDate: (v: MbscPageLoadingEvent) => void;
  events: CustomCalendarEvent[];
  isLoading?: boolean;
  appointments: AppointmentSlots[];
  isShowInlineAppointment: {
    position: 'default' | 'custom';
    showModal: boolean;
  };
  setShowInlineAppointment: (value: boolean) => void;
  setShowOtherAppointmentBooking: () => void;
}

export const FREE_BUSY_APPOINTMENT_TYPES = [AppointmentStatusType.Free, AppointmentStatusType.Busy];

type WeekDays = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

interface WeekDateInterface {
  monday: Date;
  tuesday: Date;
  wednesday: Date;
  thursday: Date;
  friday: Date;
  saturday: Date;
  sunday: Date;
}

const CalendarView = ({
  timezone,
  selectedCalendarDate,
  setSelectedCalendarDate,
  setCalendarDate,
  events,
  appointments,
  isLoading,
  isShowInlineAppointment,
  setShowInlineAppointment,
  setShowOtherAppointmentBooking
}: CalendarViewProps) => {
  const calendarRef = useRef<Eventcalendar>(null);
  const calendarContentRef = useRef<HTMLDivElement>(null);
  const { accountId } = useGetAccountId();
  const { token } = useGetAccessToken();
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string>();
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentSlots | undefined>();
  const [targetAppointment, setTargetAppointment] = useState<AppointmentSlots>();
  const [view, setView] = useState<'week' | 'day'>('week');
  const [is2xZoom, setIs2xZoom] = useState(false);
  const [horizontalViewSize, setHorizontalViewSize] = useState({
    width: `${calendarContentRef?.current?.offsetWidth}px` || '75vw',
    height: `${calendarContentRef?.current?.offsetHeight}px` || undefined
  });

  const { selectedClinicians, selectedRooms } = useAppSelector(calendarFilters);

  const { isEdgeAdminView, isEdgeReceptionist } = useGetAccountPackageView();
  const { clinicianProfile } = useGetAccountInfo();
  const dispatch = useAppDispatch();

  // used in the checkbox at the top of the calendar view component
  const [isShowWorkingSchedule, setIsShowWorkingSchedule] = useLocalStorage('pref:isShowWorkingSchedule', true);
  const [isShowCancelledAppointment, setIsShowCancelledAppointment] = useLocalStorage(
    'pref:isShowCancelledAppointment',
    true
  );
  const [calendarStartTime, setCalendarStartTime] = useLocalStorage('pref:calendarStartTime', AUTO_START_VALUE);

  const [selectedAppointmentGroup, setSelectedAppointmentGroup] = useState<number>(0);
  const [isRefetchingAppointmentAfterProcess, setIsRefetchingAppointmentAfterProcess] = useState<boolean>(false);

  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { appointmentId, serviceDelivered }: { appointmentId?: string; serviceDelivered?: string } =
    queryString.parse(search);

  const { selectedDate, selectedTime, selectedPractitioner } = useAppSelector(selectAppointmentData);
  const { practitionersList, isPractitionersListLoading, highLightList } = useFetchFilterList();

  const isHorizontalCalendarView = selectedClinicians.concat(selectedRooms).length >= 5;
  const enableWorkingScheduleView = selectedClinicians.concat(selectedRooms).length === 1;

  // To support calendar start time setting from the sidebar mini-calendar navigation
  // calculating current date time for the first navigation
  // since selectedCalendarDate is not set the time
  let currentDateTime = selectedCalendarDate;
  if (selectedCalendarDate && calendarStartTime !== AUTO_START_VALUE) {
    currentDateTime = new Date(
      selectedCalendarDate.getFullYear(),
      selectedCalendarDate.getMonth(),
      selectedCalendarDate.getDate(),
      +calendarStartTime,
      0,
      0
    );
  }
  const [hoverStateEvent, setHoverStateEvent] = useState<CustomCalendarEvent>();
  const [inlineBookingFormPositions, setInlineBookingFormPositions] = useState<CSSProperties>();

  const [isProcessAppointmentModalShow, setIsProcessAppointmentModalShow] = useState<boolean>(false);

  /* handlers & computed attrs ----------------------------------------------------------- */
  useEffect(() => {
    if (isEdgeAdminView || isEdgeReceptionist) {
      dispatch(setIsFetchingPractitioners(isPractitionersListLoading));
      if (practitionersList?.length) {
        dispatch(setPractitionerOptions(practitionersList));
      }
    }
  }, [isEdgeAdminView, isEdgeReceptionist, practitionersList, isPractitionersListLoading, dispatch]);

  useEffect(() => {
    if (selectedClinicians?.length) {
      const practitionerMainOptions: IOptionItem[] = selectedClinicians.map(
        (practitioner: CalendarFilterInterface) => ({
          value: practitioner._id,
          label: practitioner.name
        })
      );
      dispatch(setPractitionerMainOptions(practitionerMainOptions));
    }
  }, [selectedClinicians, dispatch]);

  /**
   * Listen date and time change to update hover-state
   */
  useEffect(() => {
    if (selectedDate && selectedTime) {
      const data = {
        start: convertDateWithTimezone(new Date(`${selectedDate} ${selectedTime.startTime}`), timezone),
        end: convertDateWithTimezone(new Date(`${selectedDate} ${selectedTime.endTime}`), timezone),
        type: 'hover-state',
        isActivity: false,
        ...(selectedPractitioner && {
          resource: selectedPractitioner._id
        })
      };
      setSelectedCalendarDate(new Date(selectedDate));
      setHoverStateEvent(data);
    }
  }, [selectedDate, selectedTime, timezone, setSelectedCalendarDate, selectedPractitioner]);

  // To be the assigned to data property of the calendar component
  const calendarEvents = useMemo(() => {
    let data = !isHorizontalCalendarView
      ? events.filter((event) => !event.isRoomFilter)
      : events.filter(
          (eventObj, index) =>
            index === events.findIndex((e) => e.id === eventObj.id && e.isRoomFilter === eventObj.isRoomFilter)
        );

    if (hoverStateEvent) {
      data.push(hoverStateEvent);
    }

    // show only active events / appointments
    if (!isShowCancelledAppointment) {
      data = data.filter((event) => !event.isCancelled);
    }

    return data;
  }, [events, isHorizontalCalendarView, isShowCancelledAppointment, hoverStateEvent]);

  // set calendar cells background color for
  // 1. today date
  // 2. selected practitioners working schedule
  const calendarCellsColors = useMemo(() => {
    if (isHorizontalCalendarView) {
      return undefined;
    }

    const localTimeZone = moment.tz.guess();

    const todayStartTimeFormat = momentTz.tz(
      momentTz.tz(`${moment().format('DD-MM-YYYY')} 00:00:00`, 'DD-MM-YYYY HH:mm', timezone),
      localTimeZone
    );

    const todayEndTimeFormat = momentTz.tz(
      momentTz.tz(`${moment().format('DD-MM-YYYY')} 23:59:59`, 'DD-MM-YYYY HH:mm', timezone),
      localTimeZone
    );

    // docs -> https://docs.mobiscroll.com/react/eventcalendar#opt-colors
    // highlight today
    const today = {
      start: todayStartTimeFormat,
      end: todayEndTimeFormat,
      background: '#3f52ff0d'
    };

    // Transform working schedule into calendar colors options
    // work only one selected practitioner in the filter list
    const p = selectedClinicians.length === 1 ? selectedClinicians[0] : ({} as CalendarFilterInterface);
    const { workingSchedule, workTimeZone } = p;

    // show only today highlight if not found working schedule config
    if (!isShowWorkingSchedule || !workingSchedule) {
      return [today];
    }

    if (workingSchedule && calendarRef.current) {
      // Steps of calculation
      // 1. get current range of the calendar view
      // 2. find the date according to monday to sunday
      // 3. create color config for unavailable time
      // 4. map the time slots from working schedule into white color config
      // 5. support calendar date changed

      // NOTE no-doc
      const { _firstDay: firstDay, _lastDay: lastDay } = calendarRef.current;

      calendarRef.current._el.scrollTo(0, 0);
      const startDate = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate());
      const startTimeInMs = startDate.getTime();

      // TODO assume the calendar starting at monday and the config never changed
      const CURRENT_WEEK_DATE: WeekDateInterface = {
        monday: startDate,
        tuesday: new Date(startTimeInMs + ONE_DAY_IN_MS),
        wednesday: new Date(startTimeInMs + 2 * ONE_DAY_IN_MS),
        thursday: new Date(startTimeInMs + 3 * ONE_DAY_IN_MS),
        friday: new Date(startTimeInMs + 4 * ONE_DAY_IN_MS),
        saturday: new Date(startTimeInMs + 5 * ONE_DAY_IN_MS),
        sunday: new Date(startTimeInMs + 6 * ONE_DAY_IN_MS)
      };

      // collecting cell color config
      const colors: any = [];

      // paint unavailable time on every cell
      colors.push({
        start: new Date(firstDay.getTime()).setDate(firstDay.getDate() - 1), // ensure that all cells painted
        end: lastDay,
        background: '#F2F6FB' // border color is #cfcfcf
      });

      // loop creating color config for available time slots
      for (const [day, { isActive, timeSlots }] of Object.entries(workingSchedule)) {
        if (isActive) {
          timeSlots.forEach((slot: any) => {
            const start = new Date(CURRENT_WEEK_DATE[day as WeekDays].getTime());
            const end = new Date(start.getTime());
            const startTimeFormat = momentTz.tz(
              momentTz.tz(
                `${moment(start).format('DD-MM-YYYY')} ${slot.startTime}`,
                'DD-MM-YYYY HH:mm',
                workTimeZone || timezone
              ),
              localTimeZone
            );
            const endTimeFormat = momentTz.tz(
              momentTz.tz(
                `${moment(end).format('DD-MM-YYYY')} ${slot.endTime}`,
                'DD-MM-YYYY HH:mm',
                workTimeZone || timezone
              ),
              localTimeZone
            );

            const massageColorData = {
              start: startTimeFormat,
              end: endTimeFormat,
              background: 'white'
            };

            colors.push(massageColorData);
          });
        }
      }
      return [today, ...colors];
    }

    return [today];

    // need to watch `calendarRef.current?._firstDay` changed since calendar
    // schedule view can be changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHorizontalCalendarView, isShowWorkingSchedule, selectedClinicians, calendarRef.current?._firstDay]);

  const getSelectedAppointment = async () => {
    if (selectedAppointmentId) {
      const [foundAppointment] = await Promise.all([appointments.find(({ _id }) => _id === selectedAppointmentId)]);

      const newAppointment = {
        ...foundAppointment,
        ...(foundAppointment?.startDateTime &&
          foundAppointment?.endDateTime &&
          convertUtcTimeToClinicianTime({
            startDateTime: moment(foundAppointment.startDateTime).toDate(),
            endDateTime: moment(foundAppointment.endDateTime).toDate(),
            timezone
          }))
      } as AppointmentSlots;

      setTimeout(() => {
        setSelectedAppointment(newAppointment);
      }, 10);
    }
    setSelectedAppointment(undefined);
  };

  const updateSelectedAppointment = (appointment: AppointmentSlots) => {
    setSelectedAppointment({ ...selectedAppointment, ...appointment });
  };

  useEffect(() => {
    getSelectedAppointment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointmentId, selectedClinicians, selectedRooms, timezone]);

  // navigate the calendar top bar to the given time
  const navigateToTimeValue = useCallback((newTimeValue: string) => {
    if (calendarRef.current) {
      if (newTimeValue !== 'auto') {
        const { _selected: time } = calendarRef.current;
        const d = new Date(time);
        // +1 hour to the timeValue for compensating the default offset hour navigation method
        calendarRef.current?.navigateToEvent({
          start: new Date(d.getFullYear(), d.getMonth(), d.getDate(), +newTimeValue + 1, 0, 0)
        });
      }
    }
  }, []);

  const handleNewStartTimeSetting = useCallback(
    (newTimeValue: string) => {
      navigateToTimeValue(newTimeValue);
      setCalendarStartTime(newTimeValue);
    },
    // eslint-disable-next-line
    [calendarRef.current]
  );

  momentTimezone.moment = momentTz;

  const queryAppointment = useCallback(
    async (appointmentId: string) => {
      try {
        const appointmentResponse = await getAppointmentById(token, accountId, appointmentId);
        const appointment = await appointmentResponse.json();
        setTargetAppointment(appointment);
        setSelectedCalendarDate(new Date(appointment.date));
        setSelectedAppointmentId(appointmentId);
        notification.success({
          message: 'Appointment info fetched.',
          closeIcon: <span className={'success'}>OK</span>
        });
      } catch (ex) {
        console.error(ex);
        notification.error({
          message: 'Failed to fetch appointment info.',
          duration: 2,
          closeIcon: <span className={'success'}>OK</span>
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, token]
  );

  // docs -> https://docs.mobiscroll.com/5-25-1/react/eventcalendar#event-onPageLoading
  const onPageLoading = useCallback(async (event: any) => {
    setCalendarDate(event);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEventClick = useCallback((event: any) => {
    // prevent clicking if the item is in cancelled state
    const { isCancelled, type, isRoomBookedByOthers } = event?.event || {};
    if (isCancelled || isRoomBookedByOthers || type === 'hover-state') {
      return false;
    }

    if (event?.event?.id) {
      const id = event?.event?.id.split('_')[0];
      setSelectedAppointmentId(id);
      setSelectedAppointmentGroup(event?.event?.group);
    }
  }, []);

  // rendering appointment data and the gap after the session
  // docs -> https://docs.mobiscroll.com/react/eventcalendar#opt-renderScheduleEvent
  const renderScheduleEvent = useCallback(
    (data: any) => {
      const renderGapEvent = (data: any) => {
        const eventPeriod = moment
          .duration(
            getTimeZoneDateTime(data.original.end, timezone).diff(getTimeZoneDateTime(data.original.start, timezone))
          )
          .asMinutes();
        data = {
          ...data,
          ...(!data.end && { end: getTimeZoneDateTime(data.original.end, timezone).format('hh:mm A') }),
          ...(!data.start && { start: getTimeZoneDateTime(data.original.start, timezone).format('hh:mm A') })
        };

        const scale = is2xZoom ? 2 : 1;
        const eventLong = (scale * (eventPeriod * (!isHorizontalCalendarView ? 2400 : 3072))) / 1440;
        const gapLong = (scale * (data.original.gap * (!isHorizontalCalendarView ? 2400 : 3072))) / 1440;
        const mainLong = eventLong + gapLong;

        // finding the gap height
        const MIN_IN_HOUR = 60;
        const OFFSET_PIXEL = 6; // offset value to avoid overlapping border lines
        const gapHeight =
          (scale * (data.original.gap * MIN_IN_HOUR)) / TIME_CELL_STEP -
          (MIN_IN_HOUR / TIME_CELL_STEP) * scale * OFFSET_PIXEL;

        return (
          <div style={!isHorizontalCalendarView ? { height: '100%' } : { width: mainLong }}>
            <div
              style={!isHorizontalCalendarView ? { height: '100%' } : { height: 50, width: eventLong }}
              className={styles.eventContainer}
            >
              <CalendarEvent
                data={{
                  ...data,
                  appointmentTypes: highLightList.filter((i) => i.sessionType),
                  practitionersList: practitionersList.map((practiceObj) => ({
                    ...practiceObj,
                    _id: practiceObj._id || 'practiceId'
                  }))
                }}
                is2xZoom={is2xZoom}
                isHorizontalCalendar={isHorizontalCalendarView}
                onEditEvent={() => onEventClick({ event: data })}
              />
            </div>
            <div
              style={
                !isHorizontalCalendarView
                  ? { height: gapHeight }
                  : { width: gapLong, top: 0, height: 50, left: eventLong }
              }
              className={classNames(styles.gapContainer, data.original.type === 'reserved' && styles.reservedGap)}
            >
              <div
                className={classNames(styles.gapMakeup, data.original.type === 'reserved' && styles.reservedGapBorder)}
              />
              <div className={styles.gapText}>GAP</div>
            </div>
          </div>
        );
      };

      return (
        data &&
        (data.end && data.original?.gap > 0 && !data.original.isCancelled ? (
          renderGapEvent(data)
        ) : (
          <CalendarEvent
            data={{
              ...data,
              appointmentTypes: highLightList.filter((i) => i.sessionType),
              practitionersList: practitionersList
            }}
            is2xZoom={is2xZoom}
            isHorizontalCalendar={isHorizontalCalendarView}
            onEditEvent={() => onEventClick({ event: data })}
          />
        ))
      );
    },
    [timezone, is2xZoom, isHorizontalCalendarView, onEventClick, highLightList, practitionersList]
  );

  const onViewChange = (view: any) => {
    setView(view);
  };

  const handleCloseAppointmentInformationModal = () => {
    setSelectedAppointmentId('');
    setSelectedAppointment(undefined);
  };

  const renderHeader = () => (
    <CalendarHeader
      view={view}
      onViewChange={onViewChange}
      is2xZoom={is2xZoom}
      setIs2xZoom={setIs2xZoom}
      showZoomButton={!isHorizontalCalendarView}
      isShowWorkingSchedule={isShowWorkingSchedule}
      enableWorkingScheduleView={enableWorkingScheduleView}
      setIsShowWorkingSchedule={setIsShowWorkingSchedule}
      isShowCancelledAppointment={isShowCancelledAppointment}
      setIsShowCancelledAppointment={setIsShowCancelledAppointment}
    />
  );

  const viewData = useMemo<MbscEventcalendarView>(() => {
    // view config
    // docs https://docs.mobiscroll.com/react/eventcalendar#opt-view
    const commonConfig = {
      type: view, // day or week
      allDay: false, // hiding allDay events
      startDay: 1, // start at Monday
      endDay: 0 // end at Sunday
    };

    if (isHorizontalCalendarView) {
      return {
        timeline: {
          ...commonConfig,
          timeCellStep: 30
        }
      };
    }

    return {
      schedule: {
        ...commonConfig,
        timeCellStep: TIME_CELL_STEP
      }
    };
  }, [view, isHorizontalCalendarView]);

  // sort logic, practice first, clinician second, others or rooms third
  // docs -> https://docs.mobiscroll.com/react/eventcalendar#resources

  const resources = useMemo(() => {
    const isClinician = (item: CalendarFilterInterface) => item.isClinician;

    return selectedClinicians
      .concat(selectedRooms)
      .sort((a, b) => {
        const aIsClinician = isClinician(a);
        const bIsClinician = isClinician(b);

        // First, prioritize items with an empty _id (Practice)
        if (a._id === '' && !aIsClinician) return -1;
        if (b._id === '' && !bIsClinician) return 1;

        // Then, sort alphabetically by clinicians name
        const nameComparison = a.name.localeCompare(b.name);
        if (nameComparison !== 0) return nameComparison;

        // Finally, sort by clinician status (clinicians first then room)
        if (aIsClinician && !bIsClinician) return -1;
        if (!aIsClinician && bIsClinician) return 1;

        return 0; // Keep the order the same if they are both clinicians or non-clinicians
      })
      .map((item) => ({
        id: item._id || 'practiceId',
        name:
          isClinician(item) || (item._id === '' && !isClinician(item)) ? (
            <ProfileBadge
              nameClassName={styles.horizontalClinicianName}
              noEllipsisName
              name={item.name}
              avatar={item.avatar}
            />
          ) : (
            <RoomLabel name={item.name} />
          ),
        color: CALENDAR_FILTER_COLOURS_VALUE[item.color || ''] || 'gray'
      }));
  }, [selectedClinicians, selectedRooms]);

  /* inline booking form related handlers ------------------------------------------------------ */

  const horizontalCustomDay = (day: any) => {
    const date = day.date;
    return <div className={styles.horizontalCustomDay}>{moment(date).format('ddd Do MMM YYYY')}</div>;
  };

  const handleInlineFormSubmitSuccess = async () => {
    setShowInlineAppointment(false);
    setHoverStateEvent(undefined);
  };

  const handleCloseInlineBookingForm = useCallback(() => {
    setShowInlineAppointment(false);
    setHoverStateEvent(undefined);
    dispatch(resetAppointmentData());
    dispatch(setStartValidation(false));
    dispatch(setAppointmentView(AppointmentViewType.Customise));
  }, [dispatch, setShowInlineAppointment]);

  // docs -> https://docs.mobiscroll.com/react/eventcalendar#event-onCellClick
  const handleOnScheduleCellClick = useCallback(
    ({ date, domEvent, target, resource }: MbscCellClickEvent, inst: any) => {
      // copy the selected date object
      // and will be injected to the inline
      // booking form
      const initialDate = new Date(date.getTime());

      // with proper position
      // finding the right time slot
      const targetPortionEl = target.querySelector('.' + MARK_TO_APPT_CREATION_CLASS);
      if (targetPortionEl) {
        if (targetPortionEl.classList.contains(HALF_PAST_HOUR_CLASS)) {
          initialDate.setMinutes(DEFAULT_APPT_RANGE);
        }
      }

      // calculate dynamic position
      setInlineBookingFormPositions(
        calculateInlineModalPosition(domEvent, target.offsetWidth, 800, isHorizontalCalendarView)
      );

      // Reset all selected data
      dispatch(resetAppointmentData());
      dispatch(setStartValidation(false));
      dispatch(setAppointmentView(AppointmentViewType.Customise));
      // Set date and time data to inline form
      const selectedDate = moment(initialDate).format('YYYY-MM-DD');
      const startTime = moment(initialDate).format('HH:mm');
      const endTime = moment(initialDate).add(50, 'minutes').format('HH:mm');
      const getPractitionerInfo = selectedClinicians.find(
        (practitionerObj) => practitionerObj._id === (resource !== 'practiceId' ? resource : '')
      );

      dispatch(setSelectedDate(selectedDate));
      dispatch(setSelectedTime({ startTime, endTime }));
      dispatch(setOriginalDateTime({ date: selectedDate, startTime, endTime }));

      if (isHorizontalCalendarView && getPractitionerInfo) {
        dispatch(
          setSelectedPractitioner({
            _id: getPractitionerInfo._id,
            name: getPractitionerInfo.name,
            avatar: getPractitionerInfo.avatar
          })
        );
      }

      // Set event owner and dropdown is already open
      if (!isEdgeAdminView && !isEdgeReceptionist) {
        dispatch(
          setSelectedPractitioner({
            _id: clinicianProfile?._id,
            name: clinicianProfile?.name,
            avatar: clinicianProfile?.avatar
          })
        );
        dispatch(setCurrentStep(AppointmentHumanFactorStep.Client));
      } else {
        dispatch(setCurrentStep(AppointmentHumanFactorStep.Practitioner));
      }

      // Show Inline appointment modal
      setShowInlineAppointment(true);
    },
    [
      clinicianProfile,
      isEdgeAdminView,
      isEdgeReceptionist,
      isHorizontalCalendarView,
      dispatch,
      selectedClinicians,
      setShowInlineAppointment
    ]
  );

  /* Effects --------------------------------------------------------------------- */
  useEffect(() => {
    if (token && appointmentId) {
      queryAppointment(appointmentId);
    }
  }, [appointmentId, queryAppointment, token]);

  // render start time component once the calendar view rendered
  let isTimeSelectorRendered = false; // act as local flag to determine the time selector UI is rendered on the Calendar component
  useEffect(() => {
    if (calendarRef.current) {
      if (!isTimeSelectorRendered) {
        // @ts-ignore
        const timeSettingContainerEl = document.querySelector(TOP_LEFT_OF_SCHEDULE_CALENDAR_SELECTOR);
        if (timeSettingContainerEl) {
          const root = ReactDOMClient.createRoot(timeSettingContainerEl);
          if (root) {
            root.render(
              <StartTimeSettingControl timeValue={calendarStartTime} onTimeSelected={handleNewStartTimeSetting} />
            );
            // eslint-disable-next-line
            isTimeSelectorRendered = true;
          }
        }
      }
    }
  }, [calendarRef.current, calendarStartTime]);

  // -------------------------------------------------------------------------------------------------
  // To support hover state feature this block of code
  // injects 2 html div tags into each time slot in the calendar view
  // in order to detecting hovering event
  // -------------------------------------------------------------------------------------------------
  let activeSlot: EventTarget | null = null;
  useEffect(() => {
    if (calendarRef.current) {
      const sItems = document.querySelectorAll(`${SCHEDULE_ITEM_CLASS}:empty`);
      const toggleActiveState = (ev: MouseEvent) => {
        // reset others
        if (activeSlot) {
          (activeSlot as HTMLDivElement).classList.remove(MARK_TO_APPT_CREATION_CLASS);
        }

        (ev.target as HTMLDivElement).classList.add(MARK_TO_APPT_CREATION_CLASS);
        // eslint-disable-next-line
        activeSlot = ev.target;
      };

      sItems.forEach((item) => {
        const firstDiv = document.createElement('div');
        const lastDiv = document.createElement('div');
        firstDiv.classList.add(FIRST_HALF_HOUR_CLASS);
        lastDiv.classList.add(HALF_PAST_HOUR_CLASS);

        item.append(firstDiv, lastDiv);

        firstDiv.addEventListener('click', toggleActiveState, false);
        lastDiv.addEventListener('click', toggleActiveState, false);
      });
    }
  }, [calendarRef.current, calendarRef.current?._selected]);

  const openOldAppointmentModal = () => {
    handleCloseInlineBookingForm();
    setShowOtherAppointmentBooking();
  };

  const isSelectedAppointmentLoaded = useMemo(() => {
    return (!!selectedAppointment && Object.keys(selectedAppointment).length > 0) || !!targetAppointment;
  }, [selectedAppointment, targetAppointment]);

  useEffect(() => {
    if (
      isSelectedAppointmentLoaded &&
      serviceDelivered &&
      (serviceDelivered === ServiceDelivered.Attended ||
        serviceDelivered === ServiceDelivered.ClientDidNotAttend ||
        serviceDelivered === ServiceDelivered.CancelShortNotice)
    ) {
      setIsProcessAppointmentModalShow(true);
    }
  }, [isSelectedAppointmentLoaded, serviceDelivered]);

  const handleCloseProcessAppointmentModal = () => {
    dispatch(resetProcessAppointment());
    if (appointmentId || serviceDelivered) {
      navigate(pathname, { replace: true });
      setTargetAppointment(undefined);
      setSelectedAppointmentId(undefined);
    }
    setIsProcessAppointmentModalShow(!isProcessAppointmentModalShow);
  };

  const onSubmitProcessSuccessful = async (appointmentId: string, withoutRefetch?: boolean) => {
    dispatch(resetProcessAppointment());
    setIsProcessAppointmentModalShow(!isProcessAppointmentModalShow);

    if (!withoutRefetch) {
      setIsRefetchingAppointmentAfterProcess(true);
      try {
        const appointmentResponse = await getAppointmentById(token, accountId, appointmentId);
        const appointment = await appointmentResponse.json();
        setSelectedAppointment(appointment);
      } catch (ex) {
        console.error(ex);
      } finally {
        setIsRefetchingAppointmentAfterProcess(false);
      }
    } else {
      handleCloseAppointmentInformationModal();
    }
  };

  const [width, height] = useWindowSize();
  useEffect(() => {
    const newHorizontalSize = {
      width: `${width - 355}px` || '75vw',
      height: `${height - 95}px` || '400px'
    };
    setHorizontalViewSize(newHorizontalSize);
  }, [width, height]);

  /* render main component -------------------------------------------------------------- */
  return (
    <div ref={calendarContentRef}>
      <Eventcalendar
        firstDay={1}
        ref={calendarRef}
        height={!isHorizontalCalendarView ? 'calc(100vh - 90px)' : horizontalViewSize.height}
        width={!isHorizontalCalendarView ? undefined : horizontalViewSize.width}
        renderHeader={renderHeader}
        renderScheduleEvent={renderScheduleEvent}
        selectedDate={currentDateTime}
        onSelectedDateChange={(e) => setSelectedCalendarDate(moment(e.date).toDate())}
        colors={calendarCellsColors}
        data={calendarEvents}
        view={viewData}
        onEventClick={onEventClick}
        onCellClick={handleOnScheduleCellClick}
        onPageLoading={onPageLoading} // this is the cause of saying the t inside calendar, this needed because of we need to know which page of mobiscroll showing
        dataTimezone={'utc'}
        displayTimezone={timezone}
        timezonePlugin={momentTimezone}
        className={classNames(is2xZoom ? styles.zoom2x : '', isHorizontalCalendarView && styles.horizontal)}
        showEventTooltip={false}
        renderDay={!isHorizontalCalendarView ? undefined : horizontalCustomDay}
        //@ts-ignore
        resources={!isHorizontalCalendarView ? undefined : resources}
      />
      <EventInformationModal
        visible={isSelectedAppointmentLoaded}
        onClose={handleCloseAppointmentInformationModal}
        appointmentId={selectedAppointmentId}
        appointment={
          selectedAppointment && Object.keys(selectedAppointment).length > 0 ? selectedAppointment : targetAppointment
        }
        onEditComplete={() => {
          dispatch(scheduleServicesApiSlice.util.invalidateTags([SSTagTypes.AppointmentList]));
        }}
        group={selectedAppointmentGroup}
        onCancelAppointmentComplete={() => {
          dispatch(scheduleServicesApiSlice.util.invalidateTags([SSTagTypes.AppointmentList]));
        }}
        isLoading={isLoading}
        isRefetchingAppointmentAfterProcess={isRefetchingAppointmentAfterProcess}
        updateSelectedAppointment={updateSelectedAppointment}
        isProcessAppointmentModalShow={isProcessAppointmentModalShow}
        onCloseProcessAppointmentModal={handleCloseProcessAppointmentModal}
        handleShowProcessAppointment={() => setIsProcessAppointmentModalShow(true)}
        onSubmitProcessSuccessful={onSubmitProcessSuccessful}
      />
      {isShowInlineAppointment.showModal && (
        <InlineBookingModal
          style={
            (isShowInlineAppointment.position === 'custom' && inlineBookingFormPositions) || {
              top: '20%',
              right: '248px',
              boxShadow: '1px 1px 21px #0000001a',
              maxWidth: '800px',
              width: '100%'
            }
          }
          onCloseModal={handleCloseInlineBookingForm}
          onSubmitSuccess={handleInlineFormSubmitSuccess}
          onSelectOtherType={openOldAppointmentModal}
          practitionerMainOption
          rememberSelectedPractitioner
        />
      )}
    </div>
  );
};

export default CalendarView;
