import { useMemo } from 'react';
import { CalendarPractitionerList } from 'interfaces/Practitioners/practitionersListing';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import { useGetClinicianId } from 'utils/hooks/GetAccountInfo/getClinicianId';
import { AccessRight } from 'interfaces/Clients/clinician';
import { CalendarHighLightInterface } from 'components/v2/CalendarFilter/interfaces';
import { DeliveryType, DELIVERY_TYPE_LABELS, ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { AppointmentStatusOption } from 'interfaces/Schedule/Appointment';
import { getRandomColor } from '../components/CalendarFilterCheckList/components/FilterColorBox/FilterColorBox';
import BoxBackground from '../components/CalendarFilterCheckList/components/FilterColorBox/colorBoxBackground.module.scss';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import { useGetPractitionerDetailsListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';
import { useGetClinicianProfileQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import { useGetRoomListQuery } from 'redux/endpoints/scheduleServices/room';
import { useGetAppointmentTypesQuery } from 'redux/endpoints/scheduleServices/appointmentType';
import { useAppSelector } from 'redux/hooks';
import { practiceInfo } from 'redux/slice/practiceDataSlice';

export const useFetchFilterList = () => {
  const { accountId } = useGetAccountId();

  const { isEdgeUserView, isEdgeAdminView, isEdgeReceptionist, isNormalUserView } = useGetAccountPackageView();
  const { isChargeAppointmentTagEnabled, isTwilioTelehealthEnabled } = useGetFeatureToggle();
  const { auth0ClinicianId } = useGetClinicianId();

  const { data: appointmentTypes, isLoading: isAppointmentTypesLoading } = useGetAppointmentTypesQuery({});

  const highlightList: CalendarHighLightInterface[] = useMemo(() => {
    const list: CalendarHighLightInterface[] = [];

    [
      DeliveryType.FaceToFace,
      DeliveryType.VideoCall,
      ...(isTwilioTelehealthEnabled ? [DeliveryType.PhoneCall] : []),
      DeliveryType.PhoneCallDialClient,
      DeliveryType.Other
    ].forEach((deliveryMode) =>
      list.push({
        _id: `${list.length + 1}`,
        color: `color${list.length + 1}`,
        name: DELIVERY_TYPE_LABELS[deliveryMode],
        deliveryMode
      })
    );

    [
      { appointmentType: ParticipantType.OneToOne, name: 'One to One' },
      { appointmentType: ParticipantType.Group, name: 'Group' },
      { appointmentType: ParticipantType.Activity, name: 'Activity' },
      { appointmentType: DeliveryType.Bespoke, name: 'Bespoke event' },
      { appointmentType: 'recurring', name: 'Recurring event' }
    ].forEach(({ appointmentType, name }) => {
      const newHighlight: CalendarHighLightInterface = {
        _id: `${list.length + 1}`,
        color: `color${list.length + 1}`,
        name,
        appointmentType
      };
      list.push(newHighlight);
    });

    Object.values(AppointmentStatusOption)
      .filter((status) => status !== AppointmentStatusOption.Charge || isChargeAppointmentTagEnabled)
      .forEach((appointmentStatus) =>
        list.push({
          _id: `${list.length + 1}`,
          color: `color${list.length + 1}`,
          name: appointmentStatus,
          status: appointmentStatus
        })
      );

    if (!isAppointmentTypesLoading && appointmentTypes?.data?.length) {
      const presetColorCSSCount = Object.keys(BoxBackground).filter((key) => key.match(/color(\d)/i)).length;

      appointmentTypes.data.forEach((item, index) => {
        if (!list.find((i) => i._id === (item._id || ''))) {
          list.push({
            _id: item._id || '',
            name: item.name,
            color: index < presetColorCSSCount ? `color${index + 1}` : getRandomColor() || 'color1',
            sessionType: item
          });
        }
      });
    }

    return list;
  }, [isTwilioTelehealthEnabled, isChargeAppointmentTagEnabled, appointmentTypes, isAppointmentTypesLoading]);

  const { data: practitionerList, isLoading: practitionerListLoading } = useGetPractitionerDetailsListQuery(
    {
      accountId,
      params: {
        status: 'active',
        withAccessRights: [AccessRight.Admin, AccessRight.User, AccessRight.Mentor]
      }
    },
    { skip: !(isEdgeAdminView || isEdgeReceptionist) }
  );

  const { data: clinicianProfile, isLoading: clinicianProfileLoading } = useGetClinicianProfileQuery();

  const { data: practiceDetails, isLoading: isPracticeDetailsInfoLoading } = useAppSelector(practiceInfo);

  const practitionerListWithPractice: CalendarPractitionerList[] = useMemo(() => {
    const practiceData =
      isEdgeUserView || isNormalUserView
        ? {
            _id: auth0ClinicianId,
            name: clinicianProfile?.name || '',
            avatar: clinicianProfile?.avatar || '',
            mobileNumber: clinicianProfile?.mobileNumber || '',
            workingSchedule: clinicianProfile?.workingSchedule,
            workTimeZone: clinicianProfile?.workTimeZone || ''
          }
        : {
            _id: '',
            name: practiceDetails?.name || '',
            avatar: practiceDetails?.logo || '',
            mobileNumber: practiceDetails?.mobileNumber || ''
          };

    return [practiceData, ...(practitionerList?.practitionerList || [])];
  }, [isEdgeUserView, isNormalUserView, auth0ClinicianId, clinicianProfile, practiceDetails, practitionerList]);

  const { data: roomList, isLoading: isRoomListLoading } = useGetRoomListQuery({}, { skip: isNormalUserView });

  const allPractitionerListLoading = practitionerListLoading || clinicianProfileLoading || isPracticeDetailsInfoLoading;

  return {
    practitionersList: practitionerListWithPractice,
    isPractitionersListLoading: allPractitionerListLoading,
    roomList: roomList || [],
    isRoomListLoading: isRoomListLoading,
    highLightList: highlightList,
    isHighLightListLoading: isAppointmentTypesLoading,
    isFiltersLoading:
      (!isNormalUserView ? isRoomListLoading : false) || allPractitionerListLoading || isAppointmentTypesLoading
  };
};
