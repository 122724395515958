import FilterColorBox from '../CalendarFilterCheckList/components/FilterColorBox/FilterColorBox';
import styles from './FilterItemsWithColor.module.scss';

export interface FilterItemsWithColorProps {
  sections: { sectionName?: string; items: { name: string; _id: string; color: string }[]; searchable?: boolean }[];
}

const FilterItemsWithColor = ({ sections }: FilterItemsWithColorProps) =>
  sections.filter((section) => section.items.length > 0).length > 0 ? (
    <div className={styles.container}>
      {sections.map(
        (section, index) =>
          section.items.length > 0 && (
            <div key={index}>
              {section.sectionName && <div className={styles.title}>{section.sectionName}</div>}
              <div className={styles.items}>
                {section.items.map((item, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.itemLabel}>{item.name}</div> <FilterColorBox color={item.color} />
                  </div>
                ))}
              </div>
            </div>
          )
      )}
    </div>
  ) : null;

export default FilterItemsWithColor;
