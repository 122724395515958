import { Skeleton } from 'antd';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import CalendarFilterCheckList, {
  FilterCheckListItem,
  CalendarFilterCheckListProps
} from '../CalendarFilterCheckList/CalendarFilterCheckList';
import FilterItemsWithColor, { FilterItemsWithColorProps } from '../FilterItemsWithColor/FilterItemsWithColor';
import styles from './FilterSection.module.scss';
import { scrollToView } from 'utils/scrollToView';

const sortItemsByName = (items: FilterCheckListItem[]) =>
  [...items].sort((a, b) => {
    if (a._id === '') {
      return -1;
    } else if (b._id === '') {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });

const FilterSection = ({
  title,
  icon,
  items,
  selectedFilterList,
  onChangeItem,
  searchable,
  loading,
  subSections,
  notFoundText,
  renderNoItems,
  showToggleAllButtons,
  isEditing,
  className
}: CalendarFilterCheckListProps & {
  title: string;
  icon: string;
  subSections?: FilterItemsWithColorProps['sections'];
  renderNoItems?: JSX.Element;
  isEditing?: boolean;
  className?: string;
}) => {
  const [editing, setEditing] = useState(false);
  const controlled = isEditing !== undefined;

  const handleExpand = () => {
    if (items.length === 0 || controlled) return;
    const isOpened = !editing;
    setEditing(isOpened);
    if (isOpened) {
      // have to set some time gap for the filter component to expand first before scrolling to top
      setTimeout(() => {
        scrollToView(title, undefined, true);
      }, 50);
    }
  };

  const itemWithSort = useMemo(() => sortItemsByName(items), [items]);
  const selectedFilterListWithSort = useMemo(() => sortItemsByName(selectedFilterList), [selectedFilterList]);

  return (
    <div className={classNames(styles.section, editing && styles.editing, className)}>
      <div id={title} className={styles.sectionTitle}>
        <span className={styles.title} onClick={() => handleExpand()}>
          {title}
        </span>
        <i className={classNames(styles.iconButton, 'material-icons-outlined')} onClick={() => handleExpand()}>
          {!controlled && editing ? 'keyboard_arrow_up' : icon}
        </i>
      </div>
      {loading ? (
        [...Array(3)].map((obj, i) => (
          <div key={i} className={styles.loadingWrapper}>
            <Skeleton.Input active className={styles.loading} />
          </div>
        ))
      ) : itemWithSort.length > 0 ? (
        isEditing || editing ? (
          <CalendarFilterCheckList
            items={itemWithSort}
            loading={loading}
            selectedFilterList={selectedFilterListWithSort}
            onChangeItem={onChangeItem}
            notFoundText={notFoundText}
            searchable={searchable}
            showToggleAllButtons={showToggleAllButtons}
            title={title}
          />
        ) : (
          <FilterItemsWithColor sections={[{ items: selectedFilterListWithSort }]} />
        )
      ) : (
        <div className={styles.noItems}>{renderNoItems}</div>
      )}
    </div>
  );
};

export default FilterSection;
