import DropdownSearchable from 'components/v2/DropdownSearchable/DropdownSearchable';
import { IOptionItem } from 'components/v2/DropdownSearchable/OptionItem';
import { ParticipantType } from 'interfaces/Schedule/AppointmentType';
import { ChangeEvent, useMemo, useState } from 'react';
import { useGetAppointmentTypesForInlineAppointmentQuery } from 'redux/endpoints/scheduleServices/appointmentType';
import {
  AppointmentClientOrGroupType,
  AppointmentHumanFactorStep,
  DEFAULT_ROOM_OPTION,
  selectAppointmentData,
  selectCurrentStep,
  selectPractitionerMainOptions,
  setCurrentStep,
  setIsPackageAppointment,
  setRate,
  setSelectedAppointmentType,
  setSelectedClient,
  setSelectedPractitioner,
  setSelectedRoom,
  setSelectedTime,
  startInlineCalendarValidation
} from 'redux/features/appointmentCreation/appointmentCreationSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useGetAccountPackageView } from 'utils/hooks/GetAccountInfo/accountPackageView';
import styles from './HumanFactors.module.scss';
import { useGetPermissionToggle } from 'utils/featureToggle/permissionToggle';
import moment from 'moment';
import { config } from 'config/config';
import Input from 'components/v2/Input/Input';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import classNames from 'classnames';
import { useGetPractitionerDetailsListQuery } from 'redux/endpoints/clinicianProfileServices/practitioner';
import { AccessRight } from 'interfaces/Clients/clinician';
import { useGetAccountId } from 'utils/hooks/GetAccountInfo/getAccountId';
import { useGetClinicianProfileQuery } from 'redux/endpoints/clinicianProfileServices/clinicianProfile';
import { useGetPackageBookableAppointmentQuery } from 'redux/endpoints/scheduleServices/package';
import { useGetFeatureToggle } from 'utils/featureToggle/featureToggle';
import LinkClientOrGroup from '../LinkClientOrGroup/LinkClientOrGroup';
import { useGetGeneralSettingsQuery } from 'redux/endpoints/clinicianProfileServices/generalSettings';
import { activityAppointmentValidation } from '../../../../../../Validation/InlineBookingValidationHelper';
import classnames from 'classnames';
import { CalendarFilterInterface } from 'components/v2/CalendarFilter/interfaces';
import { getRandomColor } from '../../../../../../../../../CalendarFilterSection/components/CalendarFilterCheckList/components/FilterColorBox/FilterColorBox';
import { calendarFilters, setSelectedClinicians } from 'redux/calendarAppointmentList/calendarAppointmentListDataSlice';
import { setSelectedFiltersToLocalStorage } from '../../../../../../../../../../selectedFilters/selectedFilters';

interface HumanFactorProps {
  practitionerMainOption?: boolean;
  rememberSelectedPractitioner?: boolean;
  showSelectedItemOnFirstOption?: boolean;
}

const ITEM_FLAG = 'packageItem';

const HumanFactors = ({
  practitionerMainOption,
  rememberSelectedPractitioner,
  showSelectedItemOnFirstOption
}: HumanFactorProps) => {
  const dispatch = useAppDispatch();
  const { isEdgeAdminView, isEdgeAdminUser, isNormalUserView, isEdgeUserView, isEdgeReceptionist } =
    useGetAccountPackageView();
  const { isAppointmentRateReadDenied } = useGetPermissionToggle();
  const { accountId } = useGetAccountId();
  const currentStep = useAppSelector(selectCurrentStep);
  const practitionerMainOptions = useAppSelector(selectPractitionerMainOptions);
  const { isPackageEnabled } = useGetFeatureToggle();
  const {
    selectedPractitioner,
    selectedClient,
    selectedAppointmentType,
    selectedTime,
    rate,
    selectedClientType,
    selectedGroup
  } = useAppSelector(selectAppointmentData);
  const inlineCalendarValidation = useAppSelector(startInlineCalendarValidation);
  const { selectedClinicians, selectedRooms, selectedHighLights } = useAppSelector(calendarFilters);

  const [rateInputValue, setRateInputValue] = useState('');
  const [rateInputError, setRateInputError] = useState('');

  const { data: generalSettings, isLoading: isGeneralSettingsLoading } = useGetGeneralSettingsQuery(
    {
      accountId: accountId
    },
    { skip: !accountId }
  );

  const validateFieldError = useMemo(() => {
    const checkAppointment = activityAppointmentValidation({
      selectPractitioner: Boolean(selectedPractitioner?._id || selectedPractitioner?.name), // check this 2 because of practice only ''
      selectTargetedClient:
        selectedClientType === AppointmentClientOrGroupType.Client
          ? Boolean(selectedClient?._id)
          : Boolean(selectedGroup?._id),
      selectAppointmentType: Boolean(selectedAppointmentType?.packageAppointmentId || selectedAppointmentType?._id)
    });

    return checkAppointment.errorMessage;
  }, [selectedPractitioner, selectedClient, selectedAppointmentType, selectedClientType, selectedGroup]);

  const packageOnlyAppointment = useMemo(
    () =>
      isPackageEnabled &&
      (!isEdgeAdminView && !isEdgeReceptionist
        ? generalSettings?.packageOnlyBookings.isActive
        : generalSettings?.packageOnlyBookings.isActive && !generalSettings?.packageOnlyBookings.onlyRestrictUserRole),
    [generalSettings, isEdgeAdminView, isEdgeReceptionist, isPackageEnabled]
  );

  const {
    data: appointmentTypesData,
    isLoading: isAppointmentTypesDataLoading,
    isFetching: isAppointmentTypesDataFetching
  } = useGetAppointmentTypesForInlineAppointmentQuery(
    {
      isAdmin: isEdgeAdminUser || isEdgeReceptionist || isNormalUserView,
      clinicianId: selectedPractitioner?._id || '',
      participantType: ParticipantType.Activity
    },
    { skip: (isEdgeAdminUser || isEdgeReceptionist) && selectedPractitioner === undefined }
  );

  const appointmentTypeOptions: IOptionItem[] = useMemo(() => {
    return appointmentTypesData
      ? appointmentTypesData.data.map((item) => ({
          value: item._id || '',
          label: item.name,
          ...(!isAppointmentRateReadDenied && {
            subLabel: `${config.currencySymbol}${item.rate.amount}`
          })
        }))
      : [];
  }, [appointmentTypesData, isAppointmentRateReadDenied]);

  const {
    data: practitionerOptions,
    isFetching: practitionerOptionsFetching,
    isLoading: practitionerOptionsLoading
  } = useGetPractitionerDetailsListQuery({
    accountId,
    params: {
      status: 'active',
      withAccessRights: [AccessRight.Admin, AccessRight.User, AccessRight.Mentor]
    }
  });

  const { data: clinicianProfile } = useGetClinicianProfileQuery();

  const {
    data: assignmentPackageList,
    isLoading: isAssignmentPackageListLoading,
    isFetching: isAssignmentPackageListFetching
  } = useGetPackageBookableAppointmentQuery(
    {
      accountId: accountId,
      clientRecordId: selectedClient?._id || '',
      params: {
        eventOwnerId: selectedPractitioner?._id || ''
      },
      filterByParticipantType: ParticipantType.Activity
    },
    {
      skip:
        !isPackageEnabled ||
        !selectedClient ||
        !selectedPractitioner?._id ||
        !generalSettings?.packageOnlyBookings.isActive
    }
  );

  const packageAppointmentTypeOptions: IOptionItem[] = useMemo(() => {
    return assignmentPackageList
      ? [
          ...assignmentPackageList.map((packageObj) => ({
            value: packageObj.assignee._id || '',
            label: `${packageObj.name} - ${packageObj.assignee.funder.name}`,
            itemFlag: ITEM_FLAG,
            subMenu: packageObj.appointmentTypes.map((appTypeObj) => ({
              value: appTypeObj.packageAppointmentId || '',
              label: appTypeObj.name,
              disabled: appTypeObj.isDone,
              ...(appTypeObj.isDone && {
                subLabel: <div className={styles.doneBadge}>Done</div>
              })
            }))
          }))
        ]
      : [];
  }, [assignmentPackageList]);

  const massagePractitionerList: CalendarFilterInterface[] = useMemo(() => {
    let practiceDataMassage;
    if (isEdgeUserView || isNormalUserView) {
      practiceDataMassage = {
        _id: clinicianProfile?._id || '',
        name: clinicianProfile?.name || '',
        avatar: clinicianProfile?.avatar || '',
        mobileNumber: clinicianProfile?.mobileNumber || '',
        workingSchedule: clinicianProfile?.workingSchedule,
        workTimeZone: clinicianProfile?.workTimeZone
      };
    } else {
      practiceDataMassage = {
        _id: '',
        name: clinicianProfile?.practice?.name || '',
        avatar: clinicianProfile?.practice?.logo || '',
        mobileNumber: clinicianProfile?.practice?.mobileNumber || ''
      };
    }
    return [practiceDataMassage, ...(practitionerOptions?.practitionerList || [])];
  }, [practitionerOptions, clinicianProfile, isEdgeUserView, isNormalUserView]);

  const formattedPractitionerOptions = useMemo(() => {
    return massagePractitionerList
      ? massagePractitionerList.map((practitioner) => ({
          value: practitioner._id,
          label: practitioner.name
        }))
      : [];
  }, [massagePractitionerList]);

  const handleUpdateCurrentStep = (step: AppointmentHumanFactorStep) => {
    dispatch(setCurrentStep(step));
  };

  const handleSelectPractitioner = (id: string) => {
    const selectedPractitioner = massagePractitionerList?.find((item) => item._id === id);
    dispatch(setSelectedPractitioner(selectedPractitioner));
    dispatch(setSelectedClient(undefined));
    dispatch(setSelectedAppointmentType(undefined));
    dispatch(setRate(''));
    setRateInputValue('');
    setRateInputError('');
    dispatch(
      setCurrentStep(isPackageEnabled ? AppointmentHumanFactorStep.Client : AppointmentHumanFactorStep.AppointmentType)
    );

    if (selectedPractitioner && !!rememberSelectedPractitioner) {
      const newPractitionerFilter: CalendarFilterInterface = {
        _id: selectedPractitioner._id,
        name: selectedPractitioner.name,
        avatar: selectedPractitioner.avatar,
        isClinician: true,
        workingSchedule: selectedPractitioner.workingSchedule,
        color: getRandomColor()
      };
      const isPractitionerExistInFilters = selectedClinicians.some(
        (selectedClinicianFilter) => selectedClinicianFilter._id === newPractitionerFilter._id
      );
      if (!isPractitionerExistInFilters) {
        const newSelectedFilters = [...selectedClinicians, newPractitionerFilter];
        dispatch(setSelectedClinicians(newSelectedFilters));
        setSelectedFiltersToLocalStorage({
          id: clinicianProfile ? clinicianProfile._id : '',
          isEdgeAdminView,
          selectedFilters: newSelectedFilters,
          selectedRooms,
          selectedHighLights
        });
      }
    }
  };

  const handleSelectAppointmentType = (id: string, isPackageOption?: string, packageAssigneeId?: string) => {
    const getPackageAppointment = assignmentPackageList?.map((item) => item.appointmentTypes).flat();
    const getPackageAppointmentDetails = getPackageAppointment?.find(
      (appointment) => appointment.packageAppointmentId === id
    );

    const appointmentTypeDetails = appointmentTypesData?.data.find((item) => item._id === id);
    const isPackageItem = isPackageOption === ITEM_FLAG;

    const selectedAppointmentType =
      isPackageEnabled && isPackageItem && assignmentPackageList && assignmentPackageList.length > 0
        ? getPackageAppointmentDetails
        : appointmentTypeDetails;

    dispatch(setSelectedAppointmentType(selectedAppointmentType));
    dispatch(setCurrentStep(undefined));
    dispatch(setSelectedRoom(DEFAULT_ROOM_OPTION));

    dispatch(
      setIsPackageAppointment({
        isPackageOption: isPackageItem,
        packageId:
          assignmentPackageList?.find((packageObj) => packageObj.assignee._id === packageAssigneeId)?._id || '',
        packageAssigneeId: packageAssigneeId
      })
    );

    if (selectedAppointmentType) {
      const appointmentTypeRate = selectedAppointmentType.rate.amount.toFixed(2);

      dispatch(setRate(appointmentTypeRate));
      setRateInputValue(appointmentTypeRate);
      setRateInputError('');

      if (selectedAppointmentType?.duration.minutes === 1440) {
        dispatch(setSelectedTime({ startTime: '00:00', endTime: '23:59' }));
      } else {
        const newEndTime = moment(selectedTime.startTime, 'HH:mm')
          .add(selectedAppointmentType?.duration.minutes, 'minutes')
          .format('HH:mm');
        dispatch(setSelectedTime({ ...selectedTime, endTime: newEndTime }));
      }
    }
  };

  const handleBlurRate = () => {
    const rateNumber = Number(rateInputValue);

    if (isNaN(rateNumber)) {
      setRateInputError('Please enter a valid number');
      dispatch(setRate(rateInputValue));
    } else {
      const rateValue = rateNumber.toFixed(2);
      setRateInputValue(rateValue);
      setRateInputError('');
      dispatch(setRate(rateValue));
    }
  };

  const handleChangeRate = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || '';
    setRateInputValue(value);
  };

  return (
    <>
      <div
        className={classnames(styles.container, inlineCalendarValidation && validateFieldError && styles.errorBorder)}
      >
        {(isEdgeAdminView || isEdgeReceptionist) && (
          <div className={styles.column}>
            <DropdownSearchable
              isActive={currentStep === AppointmentHumanFactorStep.Practitioner}
              onActive={() => handleUpdateCurrentStep(AppointmentHumanFactorStep.Practitioner)}
              placeholder="Practitioner Name"
              otherOptionsHeader="Other Calendar"
              searchable
              isSplittedList
              selected={selectedPractitioner?._id}
              options={formattedPractitionerOptions}
              mainOptions={practitionerMainOption ? practitionerMainOptions : undefined}
              isLoading={practitionerOptionsLoading || practitionerOptionsFetching}
              onSelect={handleSelectPractitioner}
              error={
                inlineCalendarValidation && !selectedPractitioner?._id && !selectedPractitioner?.name
                  ? 'Please select practitioner'
                  : ''
              }
              hideErrorDesc
            />
          </div>
        )}
        {isPackageEnabled && (
          <div className={styles.column}>
            <LinkClientOrGroup
              containerClassName={styles.linkClientContainer}
              separatedLine={<div className={styles.splitDash}>-</div>}
              activeClientName={currentStep === AppointmentHumanFactorStep.Client}
              showSelectedItemOnFirstOption={showSelectedItemOnFirstOption}
            />
          </div>
        )}
        <div className={styles.column}>
          <DropdownSearchable
            isActive={currentStep === AppointmentHumanFactorStep.AppointmentType}
            onActive={() => handleUpdateCurrentStep(AppointmentHumanFactorStep.AppointmentType)}
            placeholder="Activity Type"
            searchable
            selected={
              isPackageEnabled
                ? selectedAppointmentType?.packageAppointmentId || selectedAppointmentType?._id
                : selectedAppointmentType?._id
            }
            otherOptionsHeader="Other activity type"
            mainOptions={packageOnlyAppointment ? undefined : packageAppointmentTypeOptions}
            options={packageOnlyAppointment ? packageAppointmentTypeOptions : appointmentTypeOptions}
            isLoading={
              isPackageEnabled
                ? isAssignmentPackageListLoading ||
                  isAssignmentPackageListFetching ||
                  isAppointmentTypesDataLoading ||
                  isAppointmentTypesDataFetching ||
                  isGeneralSettingsLoading
                : isAppointmentTypesDataLoading || isAppointmentTypesDataFetching
            }
            isSplittedList={isPackageEnabled ? !packageOnlyAppointment : false}
            onSelect={handleSelectAppointmentType}
            disabled={!selectedPractitioner}
            noItemFoundLabel={packageOnlyAppointment ? 'No package activity found' : 'No activity found'}
            error={
              inlineCalendarValidation &&
              !(selectedAppointmentType?.packageAppointmentId || selectedAppointmentType?._id)
                ? 'Please select activity type'
                : ''
            }
            hideErrorDesc
          />
        </div>

        {!isAppointmentRateReadDenied && (
          <div className={styles.column}>
            <div>
              {selectedAppointmentType?.rate?.editable ? (
                <>
                  <Input
                    inputProps={{
                      value: rateInputValue,
                      onBlur: handleBlurRate,
                      onChange: handleChangeRate
                    }}
                    prefix={config.currencySymbol}
                    className={styles.inputContainer}
                    inputClassName={classNames(styles.input, rateInputError && styles.error)}
                  />
                  <ErrorMessage error={rateInputError} visible={!!rateInputError} />
                </>
              ) : (
                <>
                  {config.currencySymbol}
                  {rate}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {inlineCalendarValidation && <div className={styles.errorMessage}>{validateFieldError}</div>}
    </>
  );
};

export default HumanFactors;
